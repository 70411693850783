.ant-layout.ant-layout-has-sider.myfx-layout.light {
    background-color: #c6d0d9;
}
.ant-layout.ant-layout-has-sider.myfx-layout.dark {
    background-color: #0f2b53;
}

.ant-layout.myfx-layout aside {
    background-color: #0b2d5d !important;
    border-radius: 0 10px 0 0;
}
.ant-layout.myfx-layout.dark aside {
    background-color: #1a3e72 !important;
    border-radius: 0 10px 0 0;
}

.ant-layout aside .ant-layout-sider-children ul {
    background: transparent;
}

.myfx-layout.dark aside .ant-layout-sider-children ul.myfx-menu .ant-menu-item-selected {
    background-color: #063057;
}

.myfx-layout.light aside .ant-layout-sider-children ul.myfx-menu .ant-menu-item-selected {
    background-color: #0a3d6e;
}
.myfx-header {
    padding: 0 !important;
    height: auto !important;
    line-height: normal !important;
}
.myfx-header-row {
    height: 64px;
    align-items: center;
}
.myfx-logo-style {
    padding-left: 1rem;
}

.mdaq-logo-style {
    display: block;
    height: 37px;
    margin: 16px auto;
}

.mdaq-logo-style-expanded {
    display: block;
    height: 63px;
    margin: 16px auto;
}

.myfx-header .logger-info {
    text-align: right;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 16px;
}

.myfx-logout {
    background-color: #95aabc;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    cursor: pointer;
}
.ant-layout.myfx-layout.dark .myfx-logout {
    background-color: #3b6db7;
}

.myfx-layout aside .ant-layout-sider-trigger .anticon-left svg,
.myfx-layout aside .ant-layout-sider-trigger {
    background: transparent;
}
.myfx-layout aside .ant-layout-sider-trigger .anticon-left svg,
.myfx-layout aside .ant-layout-sider-trigger .anticon-right svg {
    display: none;
}
.myfx-layout aside span.trigger {
    color: white;
    position: absolute;
    bottom: 10px;
    right: 0;
    font-size: 16px;
}

.myfx-content .main-div {
    height: calc(100vh - 97px);
    padding: 24px 16px;
    overflow-y: auto;
}

.myfx-layout section .myfx-content.dark {
    background-color: #051834;
    color: #e6f7ff;
}

.myfx-layout .myfx-footer {
    background-color: #fff;
    padding: 8px 16px;
}

.myfx-layout.dark .myfx-footer {
    background-color: #051834;
    color: #e6f7ff;
}

.myfx-header .logger-info.dark span {
    color: #e6f7ff;
}

.name-ellipsis-mobile {
    display: none !important;
    font-size: 16px;
    color: #051834;
}

.name-ellipsis {
    display: block !important;
}

.ant-layout.myfx-layout .ant-menu-item-selected {
    background-color: #0a3d6e;
    color: #fff;
}
.ant-layout.myfx-layout .ant-menu {
    margin-top: 30px;
}
.ant-layout.myfx-layout.dark .ant-menu-item-selected {
    background-color: #0b2d5d;
}
.ant-layout.myfx-layout .ant-menu .ant-menu-item {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 50px;
    padding: 10px !important;
}

.ant-layout.myfx-layout .ant-layout-sider-collapsed .ant-menu .ant-menu-item {
    padding: 0;
    justify-content: center;
}
.ant-layout-sider-collapsed .ant-menu-title-content {
    display: none;
}

@media (max-width: 768px) {
    .tresure-logo-style {
        height: 20px;
    }
}

@media (max-width: 576px) {
    .myfx-logo-style {
        display: none;
    }
    .ant-layout-sider {
        position: absolute !important;
        height: 100vh;
        z-index: 99;
    }
    .ant-layout-sider.ant-layout-sider-collapsed {
        position: relative !important;
    }
    .name-ellipsis-mobile {
        display: block !important;
        color: #235a8d;
        font-weight: bold;
    }
    .name-ellipsis {
        display: none !important;
        width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
