@font-face {
    font-family: 'Avenir';
    src: url('./component/assets/fonts/AvenirLTStd-Roman.otf') format('opentype'),
        url('./component/assets/fonts/AvenirLTStd-Book.otf') format('opentype');
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-card-body {
    padding: 16px !important;
}
.ant-card {
    border: 1px solid #ced1d5;
}
.ant-layout {
    background-color: #fff;
}

.ant-btn {
    border-radius: 2px;
    box-shadow: none;
}

.myfx-layout.dark .ant-card {
    background: transparent !important;
    border-color: #2a6dae !important;
}
.myfx-layout.dark .ant-select-arrow {
    color: #fff !important;
}

.sticky-sidebar {
    position: sticky;
    top: 0px;
}

.main-div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(11, 45, 93, 0.3);
    background-color: #f5f5f5;
}
.main-div::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}
.main-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #f5f5f5;
}

.myfx-layout.light .text-color {
    color: #23416c;
}
.myfx-layout.dark .text-color {
    color: #ffffff;
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.65);
}
.myfx-modal.dark .ant-modal-content .ant-modal-header,
.myfx-modal.dark .ant-modal-content .ant-modal-title,
.myfx-modal.dark .ant-modal-content .ant-modal-title .text-white,
.myfx-modal.dark .ant-modal-content {
    background-color: #142a48 !important;
    color: #fff !important;
}
.myfx-modal.dark .ant-modal-content .ant-descriptions-item-label {
    color: #cce4ff !important;
}
.myfx-modal.dark .ant-modal-content .ant-descriptions-item-content,
.myfx-modal.dark .ant-modal-content .ant-descriptions-item-content .ant-tag.ant-tag-default {
    color: #fff !important;
}
.myfx-modal .ant-modal-content {
    border-radius: 16px;
    border-left: 20px solid #fff;
}
.myfx-modal.sell .ant-modal-content {
    border-color: #f89195;
}
.myfx-modal.buy .ant-modal-content {
    border-color: #83aed6;
}

.dark .main-div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(11, 45, 93, 0.3);
    background-color: #134452;
}
.dark .main-div::-webkit-scrollbar {
    width: 8px;
    background-color: #134452;
}
.dark .main-div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #134452;
}

.ant-modal .ant-modal-content {
    margin-block-start: 2px;
}

.dark.ant-select-dropdown .ant-select-item {
    color: #fff;
}

.dark.ant-select-dropdown {
    background-color: #051834 !important;
    border: 1px solid #153d69 !important;
}
.dark.ant-select-dropdown .ant-select-item {
    color: #fff !important;
}
.dark.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #0c294d !important;
}

.dark .ant-select-selector,
.dark input.currency-input,
.dark .ant-input,
.dark .ant-picker,
.dark .ant-input-number {
    color: #fff !important;
    border-color: #051834 !important;
    background-color: #051834 !important;
}

.trader-form-area {
    min-width: auto;
    max-width: 100%;
}
.summary-list-area {
    min-width: auto;
    max-width: 100%;
}
@media only screen and (min-width: 1200px) {
    .trader-form-area {
        min-width: 410px;
        max-width: 410px;
    }
    .summary-list-area {
        min-width: calc(100vw - 638px);
        max-width: calc(100vw - 638px);
    }
    .summary-list-area.collapsed {
        min-width: calc(100vw - 488px);
        max-width: calc(100vw - 488px);
    }
}
